.action-image {
	width: 20px;
	height: 20px;
	margin: auto 5px;
}

.action-button {
	display: flex;
	padding: 2px;
	align-items: center;
}

.action-button-disabled {
	opacity: 0.3;
	pointer-events: none;
}

.action-spinner {
	margin: auto 3px;
}

.action-text {
	margin-left: 5px;
	margin-top: 2px;
}
