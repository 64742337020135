.editing-text-format {
	height: fit-content;
	display: block;
}

.formatted-placeholder .default-formatted-placeholder-text {
	color: var(--ara-blue);
	font-style: italic;
}

.block-text-align-left .block-text:not(.no-align) .text-format.align,
.block-text-align-left .block-text:not(.no-align) .formatted-placeholder.align {
	text-align: left;
}

/* .block-text-align-left .block-text:not(.no-align) .text-format.align li,
.block-text-align-left .block-text:not(.no-align) .formatted-placeholder.align li {
	padding-left: 0.2em;
} */

.block-text-align-right .block-text:not(.no-align) .text-format.align,
.block-text-align-right .block-text:not(.no-align) .formatted-placeholder.align {
	text-align: right;
	/* list-style-position: inside; */
}

.block-text-align-center .block-text:not(.no-align) .text-format.align,
.block-text-align-center .block-text:not(.no-align) .formatted-placeholder.align {
	text-align: center;
	/* list-style-position: inside; */
}

.block-text-align-justify .block-text:not(.no-align) .text-format.align,
.block-text-align-justify .block-text:not(.no-align) .formatted-placeholder.align {
	text-align: justify;
}

.heading-text-align-left .heading-text:not(.no-align) .text-format.align,
.heading-text-align-left .heading-text:not(.no-align) .formatted-placeholder.align {
	text-align: left;
}

.heading-text-align-right .heading-text:not(.no-align) .text-format.align,
.heading-text-align-right .heading-text:not(.no-align) .formatted-placeholder.align {
	text-align: right;
	/* list-style-position: inside; */
}

.heading-text-align-center .heading-text:not(.no-align) .text-format.align,
.heading-text-align-center .heading-text:not(.no-align) .formatted-placeholder.align {
	text-align: center;
	/* list-style-position: inside; */
}

.heading-text-align-justify .heading-text:not(.no-align) .text-format.align,
.heading-text-align-justify .heading-text:not(.no-align) .formatted-placeholder.align {
	text-align: justify;
}

.text-format {
	font-weight: 300;
	min-height: 20px;
	width: 100%;
	max-width: 100%;
}

.text-format p {
	margin-bottom: calc(0.2rem * var(--local-line-height));
	/* at default 1.5 matches previous default 0.3rem */
}

.spacing-spaced .text-format p {
	margin-bottom: calc(0.2666666rem * var(--local-line-height));
	/* at default 1.5 matches previous default 0.4rem */
}

.spacing-compact .text-format p {
	margin-bottom: calc(0.1333333rem * var(--local-line-height));
	/* at default 1.5 matches previous default 0.2rem */
}

.normal300 .text-format,
.text-format .normal300 {
	font-weight: 300;
}

.normal400 .text-format,
.text-format .normal400 {
	font-weight: 400;
}

.text-format u {
	text-decoration: none;
}
/* Hack to make underline work.
It seems underline isn't recognised by html canvas by inheritance, 
so take it off and reapply to the nested text child object */
.text-format u span {
	text-decoration: underline;
}

.text-format strong {
	font-weight: 500;
}

.bold500 .text-format strong,
.text-format .bold500.in-text-formatter-font strong,
.text-format strong .bold500.in-text-formatter-font {
	font-weight: 500;
}

.bold600 .text-format strong,
.text-format .bold600.in-text-formatter-font strong,
.text-format strong .bold600.in-text-formatter-font {
	font-weight: 600;
}

.bold700 .text-format strong,
.text-format .bold700.in-text-formatter-font strong,
.text-format strong .bold700.in-text-formatter-font {
	font-weight: 700;
}

.text-format .highlighted {
	color: var(--ara-purple);
	font-weight: 500;
}

.template-mode .text-format * {
	color: inherit;
}

.editing-text-format {
	/* Needs to be set to avoid inheriting grab and move in template */
	cursor: text;
}

.slate-no-edit,
.text-format .slate-no-edit-children :not(.slate-no-edit) {
	user-select: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
}

.select-all * {
	user-select: all !important;
}

.slate-no-edit::-moz-selection {
	background-color: transparent;
	color: #000;
}
/* .slate-no-edit::selection {
	background-color: transparent;
	color: #000;
} */

.slate-no-edit :not(:has([data-slate-node], [data-slate-leaf], [data-slate-string]), [data-slate-string])::selection {
	background-color: transparent;
	color: #000;
}

.text-format :not(.slate-no-edit) {
	user-select: text;
	-webkit-user-select: text;
}

.text-format ul,
.text-format ol {
	margin-bottom: 0.5rem;
	margin-bottom: calc(0.3333333rem * var(--local-line-height));
}

.text-format ul li ul,
.text-format ol li ol {
	margin-bottom: calc(0.0666666rem * var(--local-line-height));
	/* prevent large gaps in the middle of nested bullets */
}

.spacing-spaced .text-format ul li ul,
.spacing-spaced .text-format ol li ol {
	margin-bottom: calc(0.1333333rem * var(--local-line-height));
	/* prevent large gaps in the middle of nested bullets */
}

.spacing-compact .text-format ul li ul,
.spacing-compact .text-format ol li ol {
	margin-bottom: 0;
	/* prevent large gaps in the middle of nested bullets */
}

.spacing-spaced .text-format ul,
.spacing-spaced .text-format ol {
	margin-bottom: calc(0.4rem * var(--local-line-height));
}

.spacing-compact .text-format ul,
.spacing-compact .text-format ol {
	margin-bottom: calc(0.2666666rem * var(--local-line-height));
}

.text-format ul,
.text-format ol {
	padding-inline-start: 0;
	position: relative;
	margin-left: 0;
}

.text-format li::marker {
	/* all bullets */
	font-family: "Arial", sans-serif;
	font-size: inherit;
	font-weight: 400;
}

.text-format li {
	margin-inline-start: var(--base-bullet-margin-left);
	padding-inline-start: var(--bullet-padding-left);
}

.text-format li li {
	margin-inline-start: var(--bullet-margin-left);
}

.text-format li.hide-bullet {
	padding-inline-start: 0;
}

.hide-bullet::marker,
.hide-bullet::before {
	/* all bullets */
	position: absolute;
	color: transparent !important;
	content: "";
}

.text-format :not(li) > ul > li,
.text-format > ul > li {
	/*top-level bullet */
	list-style-type: var(--bullet-symbol);
}
.text-format :not(li) > ul > li::marker,
.text-format > ul > li::marker {
	/*top-level bullet */
	color: var(--bullet-color);
}

/* .text-format ul ul,
.text-format ul ol,
.text-format ol ul,
.text-format ol ol {
	/* remove margin below first bullet */
/* margin-bottom: 0; */
/* } */

.text-format :not(li) > ul > li > ul > li,
.text-format :not(li) > ol > li > ul > li,
.text-format > ul > li > ul > li,
.text-format > ol > li > ul > li {
	/* second-level bullet */
	list-style-type: var(--sub-bullet-symbol);
}

.text-format :not(li) > ul > li > ul > li::marker,
.text-format :not(li) > ol > li > ul > li::marker,
.text-format > ul > li > ul > li::marker,
.text-format > ol > li > ul > li::marker {
	/* second-level bullet */
	color: var(--sub-bullet-color);
}
.text-format :not(li) > ul > li > ul > li li,
.text-format > ul > li > ul > li li,
.text-format :not(li) > ul > li > ol > li li,
.text-format > ul > li > ol > li li {
	/* more than second-level bullet */
	list-style-type: "-";
}

.text-format :not(li) > ul > li > ul > li li::marker,
.text-format > ul > li > ul > li li::marker,
.text-format :not(li) > ul > li > ol > li li::marker,
.text-format > ul > li > ol > li li::marker {
	/* more than second-level bullet */
	color: var(--sub-bullet-color);
}

.text-format ol > li::before {
	content: "" !important;
}

.ai-formatting-icon {
	font-size: 16px;
	font-weight: lighter;
	color: black;
	margin-right: 5px;
	font-family: var(--ara-font-family);
	padding: 0 5px;
}

.processing-dropdown .btn:focus {
	outline: none;
	box-shadow: none;
}

.processing-dropdown a:active {
	background-color: #eeeeee;
}

.processing-dropdown *,
.select-all .processing-dropdown {
	user-select: none !important;
}

.processing-dropdown .dropdown-toggle {
	padding-top: 0;
	padding-bottom: 0;
	font-size: inherit;
	border: none;
}

.divider-element ::selection {
	background-color: transparent;
}

.divider-element {
	display: flex;
}

.divider-icon {
	width: 20px;
	border-bottom: 2px solid black;
}

.divider-icon-outer {
	width: 32px;
	height: 35px;
	padding: 16.5px 6px;
}

.page-break-button svg {
	width: 24px;
	height: 24px;
}

.output-text-format .page-break,
.output-text-format .column-break {
	display: none;
}

/* .column-break .column-break-divider {
	display: none;
}

.template-placing .column-break .column-break-divider {
	display: flex;
} */

/* .column-break {
	break-after: column;
} */

.page-break .page-break-divider,
.page-break .page-break-divider *,
.column-break .column-break-divider,
.column-break .column-break-divider * {
	user-select: none;
}

.page-break .page-break-divider {
	width: 100%;
	margin: 0;
}

.page-break .page-break-divider .col-2 {
	padding: 0 20px;
	flex: 0 0 30%;
	max-width: 30%;
	font-size: 16px;
}

.output-text-format .page-break .page-break-divider,
.output-text-format .column-break .column-break-divider {
	display: none;
}

.two-columns {
	column-count: 2;
	/* column-gap: 7%; */
	margin-bottom: calc(0.2rem * var(--local-line-height));
}

.three-columns {
	column-count: 3;
	/* column-gap: 5%; */
	margin-bottom: calc(0.2rem * var(--local-line-height));
}
