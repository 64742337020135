#output-body-element .recruiterPhoto {
	width: 140px;
}

.recruiterLabel {
	margin-top: auto;
	margin-bottom: auto;
	width: 100%;
	height: 0;
	padding-bottom: 100%;
	overflow: hidden;
	border-radius: 50%;
}

.adminTab .recruiterLabel {
	width: 180px;
	height: 180px;
	padding-bottom: 0;
}

.adminTab .recruiterPhoto {
	width: 180px;
}

.recruiterPhoto {
	width: 160px;
	object-fit: cover;
}

#recruiter-photo {
	display: none;
}
