.numerical-dropdown-options {
	border: 1px solid #aaaaaa;
	overflow-y: scroll;
	height: 100px;
	border-radius: 3px;
}

.numerical-dropdown-option {
	width: 40px;
	min-width: max-content;
	text-align: right;
	padding: 0px 8px;
	border-top: 1px solid #eeeeee;
	position: relative;
	height: 20px;
	font-size: 13px;
	font-weight: 400;
	font-family: "Open Sans";
	color: black;
}

.selected-numerical-dropdown-option::before,
.numerical-dropdown-options .toolbar-button-active::before {
	position: absolute;
	content: "";
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: var(--ara-blue);
	left: 5px;
	top: 50%;
	transform: translateY(-50%);
}

.font-size-options {
}

.font-size-option {
}

.font-size-option.selected-numerical-dropdown-option::before {
}
