.data-tag-inline {
	display: inline;
	margin-left: 0.05em;
	margin-right: 0.05em;
}

.data-tag-template.data-tag-block {
	margin-bottom: 0.4em;
}

.data-tag-block {
	display: block;
}

#output-body-element .data-tag-block {
	display: block;
	margin-left: -0.4em;
	margin-right: -0.4em;
}

#output-body-element .text-format:not(.editing-text-format) .data-tag-empty {
	margin-left: 0.05em;
	margin-right: 0.05em;
}

.slate-table .data-tag-block {
	margin: 0;
}

#output-body-element .slate-table .data-tag-block > *:nth-last-child(2) {
	padding-bottom: 0;
	margin-bottom: 0;
}

/* .data-tag.data-tag-inline {
    white-space: nowrap;
} */

.text-format:not(.editing-text-format) .table-cell-content .data-tag.data-tag-inline {
	display: inline-block;
	margin-top: 0px;
	margin-bottom: 0px;
}

.data-tag-refreshing {
	transform-origin: 51% 50%;
	animation: spin 1s infinite linear;
}

.data-tag-name,
.data-tag-template-name {
	direction: ltr;
}

.data-tag,
.data-tag-editing .data-tag-name {
	background-color: #f5f5f5;
	border: #e0e0e0 1px solid;
	--tag-vertical-padding: calc(0.2em * calc(var(--local-line-height) - 1));
	padding: var(--tag-vertical-padding) 0.4em;
	margin-top: calc(-1 * var(--tag-vertical-padding));
	margin-bottom: calc(-1 * var(--tag-vertical-padding));
	border-radius: 2px;
}

.data-tag-editable:not(.data-tag-editing, .data-tag-error) {
	background-color: #ffffff00 !important;
	border-left: #ffffff00 0px solid !important;
	border-right: #ffffff00 0px solid !important;
	border-top: #ffffff00 1px solid !important;
	border-bottom: #ffffff00 1px solid !important;
}
.data-tag-inline.data-tag-editable:not(.data-tag-editing) {
	margin-left: -0.4em;
	margin-right: -0.4em;
}

.data-tag-editing .data-tag-name {
	text-transform: none;
}

.data-tag.data-tag-ai {
	background-color: var(--ara-pale-purple) !important;
}

.data-tag-ai .data-tag-name-outer {
	background-color: white;
	opacity: 1;
}

.data-tag-ai .data-tag-remove {
	background-color: transparent;
}

.data-tag-ai .data-tag-name {
	background-color: var(--ara-pale-purple) !important;
}

/* .data-tag.data-tag-ai .data-tag-missing-warning,
.data-tag.data-tag-ai .data-tag-name-outer {
	color: var(--ara-purple);
} */

.text-format:not(.editing-text-format) .data-tag-empty li,
.data-tag-empty:not(.data-tag-selected) li {
	/* Hide empty bullet points in empty tags when not being edited*/
	opacity: 0;
}

.data-tag-empty,
.data-tag-editing {
	position: relative;
	background-color: rgb(0 0 0 / 4%) !important;
}

.editing-text-format .data-tag-empty,
.editing-text-format .data-tag-editing.data-tag-selected {
	margin-bottom: 1px;
}

.data-tag-template-name {
	display: inline-flex;
	gap: 2px;
}

.data-tag-template-name > span {
	white-space: nowrap;
}

.data-tag-template-name > p {
	margin-bottom: 0;
}

.editing-text-format .data-tag-selected {
	border-color: var(--ara-blue);
}

.editing-text-format .data-tag-empty:hover .data-tag-name,
.editing-text-format .data-tag-editing:hover .data-tag-name,
.editing-text-format .data-tag-empty:hover .data-tag-name-outer,
.editing-text-format .data-tag-editing:hover .data-tag-name-outer {
	visibility: visible;
	transition: visibility 0.05s ease-in-out;
	transition-delay: 0.2s;
}

.data-tag-editing .data-tag-name {
	visibility: hidden;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: left;
	line-height: 1.2;
	font-size: 10px;
	width: max-content;
}

.data-tag-editing .data-tag-name-outer {
	visibility: hidden;
	position: absolute;
	top: -2px;
	left: -1px;
	width: max-content;
	height: max-content;
	transform: translateY(-100%);
	z-index: 2;
}

.data-tag-non-editable {
	cursor: not-allowed;
	user-select: all;
}

.data-tag-non-editable span[data-slate-string="true"] {
	opacity: 0.5;
}

.data-tag-empty,
.data-tag-error {
	margin-bottom: 3px;
}

.data-tag-error {
	border-color: var(--ara-red);
}

.data-tag-empty {
	border-color: var(--ara-blue);
	margin-bottom: 3px;
}

.editing-text-format .data-tag-empty,
.editing-text-format .data-tag-error {
	cursor: pointer;
}

.editing-text-format .data-tag-empty > span:not(.slate-no-edit),
.editing-text-format .data-tag-error > span:not(.slate-no-edit) {
	padding-left: 5px;
	margin-left: -5px;
	padding-right: 5px;
	margin-right: -5px;
	cursor: text;
}

.data-tag-remove {
	background-color: #f5f5f5;
	width: 15px;
	height: 15px;
	display: block;
	z-index: 3;
	text-align: center;
}

.data-tag-remove > img {
	position: absolute;
	width: 8px;
	height: 8px;
	top: 50%;
	right: 5px;
	transform: translateY(-50%);
}

.data-tag-fixed::before {
	background-color: #f5f5f5;
	content: "@";
}

.data-tag-block.data-tag-empty {
	position: relative;
}

.data-tag-block .data-tag-missing-warning {
	position: absolute;
	left: 5px;
	top: 2px;
}

.data-tag-missing-warning {
	color: black;
	opacity: 0.5;
	pointer-events: none;
}

.data-tag-dropdown {
	background-color: white;
	/* border: 1px solid #eeeeee; */
	overflow-y: scroll;
	max-height: 250px;
	border-radius: 3px;
	box-shadow: 0px 0.6px 0px 0.3px rgb(0 0 0 / 25%);
	padding: 1px;
}

.data-tag-dropdown-decorator {
	z-index: 20;
}

.data-tag-dropdown-item {
	font-family: var(--ara-font-family);
	font-size: 12px;
	padding: 3px 8px;
	border: 1px solid white;
	border-radius: 3px;
}

.data-tag-dropdown:not(:hover) .data-tag-dropdown-item-selected,
.data-tag-dropdown-item:hover {
	background-color: #eeeeee;
}

.data-tag-dropdown-item-selected,
.data-tag-dropdown-item:hover {
	border-color: #eeeeee;
}
