.input-like-grid-with-label {
	display: grid;
	grid-template-columns: 15rem 1fr;
	align-items: center;
	min-height: 39px;
}
.input-like-grid-with-label label {
	margin: 10px 0;
	padding-left: 5px;
}
