.custom-dropdown-options-text-transform {
	border: 1px solid #aaaaaa;
	border-radius: 3px;
	overflow-y: auto;
	max-height: calc(100vh - 200px);
}

.custom-dropdown-option-text-transform {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 3px;
	padding: 9px;
	width: 100%;
	height: 100%;
	border-top: 1px solid #eeeeee;
}

.custom-dropdown-option-text-transform:first-child {
	border-top: none;
}
