.account-form-container {
	display: flex;
	flex-direction: row;
	padding-bottom: 20px;
}

.row-one {
	width: 360px;
	justify-items: end;
	display: grid;
}
.row-two {
	padding-left: 25px;
}
.row-three {
	padding-left: 25px;
}

.account-access-button {
	background-color: #f8f9fa;
	box-sizing: border-box;
	width: 100px;
	height: 38px;
	right: 640px;
	border: 1px solid #ced4da;
	box-shadow: 0px 0.7px 0px 1.2px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
}

.account-access-button-span {
	font-family: var(--ara-font-family);
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
}

.account-form-date {
	font-family: "Arial";
	font-style: normal;
	text-transform: uppercase;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
}

.label {
	font-family: var(--ara-font-family);
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}
