.toolbar-hovering-col {
	margin-bottom: -45px;
}
.toolbar-col {
	height: 45px;
	width: fit-content !important;
	max-width: fit-content;
	padding: 0 !important;
}

.toolbar-filling-col {
	margin: 2px 0px;
	max-width: 50vh;
	min-width: fit-content !important;
}

.toolbar-filling-col #added-toolbar {
	padding: 0;
}

.toolbar-filling-col .formatting-toolbar {
	min-width: max-content;
}

.toolbar-filling-col .formatting-toolbar .toolbar-button:first-child {
	box-shadow: none;
}

#toolbar-space {
	justify-content: center;
	background-color: white;
	position: relative;
}

.toolbar-hovering-col #toolbar-space {
	box-shadow:
		0 -1px 0 0 lightgray,
		0 1px 0 0 lightgray;
}

.toolbar-row {
	width: 100%;
	padding: 5px 30px;
	pointer-events: auto;
}

.formatting-toolbar {
	overflow: hidden;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	max-width: calc(100% - 6px);
	max-height: 100%;
	font-family: var(--ara-font-family);
	/* box-shadow: 1px 0 0 0 lightgrey; */
	font-size: 13px;
	width: fit-content;
}

.formatting-toolbar-center {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 5px;
}

@media (max-width: 900px) {
	.formatting-toolbar-center {
		position: relative;
		left: 0;
		transform: translateX(0);
		margin-top: 5px;
	}
}

.formatting-toolbar-right {
	padding-right: 5px;
	margin-top: 5px;
}

.toolbar-full-border {
	/* border: 1px solid grey; */
	box-shadow: 0 0 0 1px grey;
	border-radius: 3px;
}

.formatting-toolbar * {
	font-size: 18px;
	line-height: 1.3;
}

.toolbar-button {
	width: fit-content;
	min-width: 35px;
	height: 35px;
	flex-grow: 0;
	text-align: center;
	vertical-align: middle;
	font-weight: 400;
}

.vertical-prop-dropdown {
	flex-direction: column;
	display: flex;
}

.vertical-dropdown.vertical-prop-dropdown {
	border: none;
}

.toolbar-button.compact-toolbar-button {
	width: 28px;
	height: 28px;
}

.formatting-toolbar > .tooltip-wrap > .toolbar-button,
.formatting-toolbar > .toolbar-button,
.toolbar-button-left-border {
	margin-left: 1px;
	box-shadow: -1px 0 0 0 lightgrey;
}

.inner-toolbar-button {
	border: 1px solid #ffffff01;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.toolbar-button-active {
	/* opacity: 1; */
	background-color: lightgray !important;
	border-radius: 3px;
}

.toolbar-button-inactive {
	/* opacity: 0.5; */
	background-color: white;
}

.toolbar-button-icon {
	font-family: var(--ara-font-family);
	font-size: 18px;
	color: black;
	user-select: none;
}

@-webkit-keyframes toolbarAppear {
	from {
		margin-left: -100%;
	}

	/* 40% {
        margin-left: -100%;
    } */

	to {
		margin-left: 0;
	}
}

@keyframes toolbarAppear {
	from {
		margin-left: -100%;
	}

	/* 40% {
        margin-left: -100%;
    } */

	to {
		margin-left: 0;
	}
}

.toolbarSlideAnimation {
	-webkit-animation: toolbarAppear;
	-webkit-animation-duration: 0.25s;
	animation: toolbarAppear;
	animation-duration: 0.25s;
	animation-timing-function: ease-in-out;
}

.alignment-icon {
	height: 15px;
}

.h-drop-item .alignment-icon {
	height: 20px;
}

.compact-toolbar-button.toolbar-button {
	min-width: 28px;
}

.compact-toolbar-button .alignment-icon {
	height: 15px;
}

.toolbar-message {
	color: var(--ara-blue);
	font-size: 15px;
}

.toolbar-button-unavailable.unclickable {
	opacity: 0.3 !important;
}

.disabled-text-transform {
	opacity: 0.5;
	cursor: not-allowed;
}

.formatting-toolbar .h-drop-title::after {
	content: none;
}

.vertical-dropdown {
	border: 1px solid #aaaaaa;
	overflow-y: scroll;
	border-radius: 3px;
}

.numeric-dropdown {
	height: 100px;
}

.numeric-dropdown .toolbar-dropdown-item {
	max-width: 40px;
	text-align: right;
	padding: 0px 8px;
	border-top: 1px solid #eeeeee;
	position: relative;
	height: 20px;
	font-size: 13px;
	font-weight: 400;
	font-family: "Open Sans";
	color: black;
}

.numeric-dropdown .h-drop-item,
.numeric-dropdown .toolbar-dropdown-item * {
	text-align: right;
}

.numeric-dropdown .h-drop-item {
	width: 100%;
}

.text-dropdown {
	height: 300px;
}

.text-dropdown .toolbar-dropdown-item {
	text-align: left;
	padding: 0px 8px 0px 20px;
	position: relative;
	font-size: 16px;
	color: black;
}

.text-dropdown .toolbar-dropdown-item * {
	text-align: left;
}

.formatting-toolbar.icon-and-text-dropdown {
	font-size: 16px;
	color: black;
	background: white;
	/* width: 100%; */
	padding: 0.31rem;
	width: max-content;
}
.formatting-toolbar.icon-and-text-dropdown .vertical-dropdown {
	overflow-y: auto;
}
.formatting-toolbar.icon-and-text-dropdown .h-drop-item .toolbar-button {
	font-size: 16px;
	font-family: inherit;
	font-style: normal;
	font-weight: 400;
	display: flex;
	justify-content: left;
	align-items: center;
	gap: 1rem;
	color: black;
	padding: 0.3rem 0.5rem;
	margin: 0.2rem 0;
}
.formatting-toolbar.icon-and-text-dropdown .h-drop-item .toolbar-button svg {
	width: 24px;
	height: 24px;
}

.formatting-toolbar.icon-and-text-dropdown .h-drop-item .toolbar-button-active {
	width: 100%;
}
