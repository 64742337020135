.case-size-options {
	border: 1px solid #aaaaaa;
	border-radius: 3px;
	overflow-y: scroll;
}

.case-size-option-section {
	width: 130px;
	height: 100%;
	display: flex;
}

.case-size-option {
	display: flex;
	align-items: center;
	padding-right: 8px;
	padding-left: 8px;
	position: relative;
	font-size: 13px;
	font-weight: 400;
	font-family: "Open Sans";
	color: black;
}

.selected-case-option::before {
	position: absolute;
	content: "";
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: var(--ara-blue);
	top: 50%;
	transform: translateY(-50%);
}

.case-item-icon {
	color: rgba(0, 0, 0, 0.5);
	font-size: 14px;
	margin-right: 5px;
	margin-left: auto;
}
