.hover-dropdown {
	height: 100%;
}

.dropdown-toggle-split {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.has-preview.download-dropdown-menu .dropdown-item:first-child {
	background-color: #f0f0f0;
}
