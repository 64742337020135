.team-container-userview .tableScrollContent {
	width: 20%;
	max-height: 65vh;
}

.team-container-userview .tableView {
	display: flex;
}

.team-container-userview .user-info-container {
	width: 80%;
	max-height: 65vh;
}
