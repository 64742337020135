.search-bar {
	display: flex;
	justify-content: center;
	align-items: center;
}

.search-bar input {
	margin-left: 5px;
	width: 200px;
	border: none;
	border-bottom: 1px black solid;
}

.search-bar img {
	width: 15px;
	height: 15px;
	padding: 2px;
	margin: 6px 0 5px -19px;
	border-radius: 50%;
	border: lightgrey 2px solid;
}

.search-bar > svg {
	margin-top: 1.5px;
}

/* dropdown search bar styles */

.dropdown .search-bar {
	justify-content: flex-start;
	margin-right: 10px;
}

.dropdown .search-bar > svg {
	margin: auto 4px;
	width: 20px;
}

.dropdown .search-bar .search-input {
	flex: 1;
	margin-left: 0;
}

.dropdown .search-bar img {
	margin-top: auto;
	margin-bottom: auto;
}
