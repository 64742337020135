.text-editor-outline {
	outline: none;
	border: none;
	border-radius: 1.5px;
	box-shadow: 0 0 0 2px var(--ara-blue);
	min-height: 1.5rem;
	padding: 2px;
	margin: -2px;
}

.editable-text {
	color: black;
	overflow-wrap: break-word;
	white-space: pre-wrap;
}

textarea.editable-text {
	text-transform: inherit;
}

.text-editor-unselected {
	word-wrap: break-word;
	white-space: pre-wrap;
	min-height: fit-content;
}

.edit-text-default {
	color: var(--ara-blue);
	display: inline;
	text-shadow: none;
	font-size: 0.9em;
	font-style: italic;
}

.editable-text.editing-date {
	font-weight: inherit;
}

.editable-text.editing {
	height: 1.2em;
	min-height: 1.2em;
	width: 100%;
	font-weight: inherit;
	overflow: hidden;
}

textarea.editable-text {
	resize: none;
	max-width: 100%;
	background: transparent;
}

.ara-input {
	height: 1.5em;
	color: inherit;
	outline: none;
	border: 1px solid black;
	border-radius: 2px;
	font-weight: inherit;
}

.ara-input:focus {
	box-shadow: 0 0 0 2px var(--ara-blue);
	border: none;
	margin: 0px 1px;
}

.two-place-input {
	width: 32px;
}

.two-place-input:focus {
	width: 30px;
}

.four-place-input {
	width: 42px;
}

.four-place-input:focus {
	width: 40px;
}

.current-input {
	width: 100px;
	max-width: 16vw;
}

.editable-formatted-text {
	--format-area-padding: 15px;
	--minus-format-area-padding: calc(-1 * var(--format-area-padding));
	margin-left: var(--minus-format-area-padding);
	margin-right: var(--minus-format-area-padding);
	padding-right: var(--format-area-padding);
	padding-left: var(--format-area-padding);
}

#CVContent .editable-formatted-text {
	--format-area-padding: 18px;
}
