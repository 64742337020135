.header {
	background-color: #fff;
	box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
	border-radius: 0px;
	padding: 20px;
	position: relative;
	border-bottom: 1px solid #e5e5e5;
}
.header-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
