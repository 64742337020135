.font-options {
	border: 1px solid #aaaaaa;
	overflow-y: scroll;
	height: 300px;
	border-radius: 3px;
}

.font-option {
	text-align: left;
	padding: 0px 8px 0px 20px;
	position: relative;
	font-size: 16px;
	color: black;
}

.selected-font-option::before {
	position: absolute;
	content: "";
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: var(--ara-blue);
	left: 5px;
	top: 50%;
	transform: translateY(-50%);
}
