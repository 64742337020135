@media (min-width: 575px) {
	.tableView.CardView {
		margin: 16px;
	}
}

@media (max-width: 576px) {
	.tableView.CardView {
		margin: 16px 0;
		border-radius: 0;
	}
}

.tableView.CardView {
	padding-top: 30px;
}

.table-title {
	margin-bottom: 10px;
	text-align: center;
}

.titleRow {
	color: var(--ara-blue);
	margin: 5px;
}

.tableRow {
	border: 1px solid #dddddd;
	border-radius: 10px;
	margin: 5px;
	font-size: 12px;
}

.tableRow .col {
	text-align: center;
	margin: auto;
}

.titleRow .titleRowCol {
	text-align: center;
}

.tableScrollContent {
	overflow-x: auto;
}

.tableContent {
	min-width: fit-content;
}

.noInfoPlaceholder {
	text-align: center;
	vertical-align: middle;
	padding-top: 50px;
	height: 150px;
}

.selection {
	padding: 3px 10px;
	width: 33px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: default;
}

.titleRow .selection {
	margin-left: 1px;
}

.selection input {
	accent-color: var(--ara-purple);
}

.selection-count {
	margin-top: 3px;
	font-size: 10px;
	color: black;
	text-align: center;
	width: max-content;
}

.table-page-container {
	width: 100%;
	margin-left: 0;
	margin-right: 0;
}

.pager {
	width: fit-content;
	margin: 10px auto;
	display: flex;
	align-items: center;
}

.pager-number {
	min-width: 50px !important;
}

.pager > * {
	display: inline-block;
	margin: auto 5px;
	min-width: 30px;
	text-align: center;
	line-height: 1.2;
}

.pager-forward > img {
	transform: rotate(90deg);
}

.pager-back > img {
	transform: rotate(-90deg);
}

.pager-forward > img,
.pager-back > img {
	height: 22px;
	margin: auto;
}

.highlighted-table-row {
	border: 2px solid var(--ara-blue);
}

.summary-row {
	background-color: var(--ara-pale-purple);
	font-weight: 400;
}
