.user-info-container .CardView {
	box-shadow: none;
	border: 1px solid #e0e0e0;
}

.user-info-view-card {
	height: calc(100%);
	margin: 0px 40px 0px;
}

.user-info-view-inner {
	width: 100%;
	overflow-y: auto;
	max-height: calc(100% - 40px);
	margin: 20px 0px;
	height: calc(100% - 40px);
}

.user-info-view-scroll {
	width: 100%;
}

.user-info-title-row {
	display: flex;
	justify-content: space-between;
}

.user-info-section {
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
}

.user-info-section .sign-up-input-field,
.user-info-section .sign-up-select {
	font-family: var(--ara-font-family);
	display: flex;
	flex-direction: row;
	max-width: 1200px;
	width: 100%;
	box-sizing: border-box;
	font-size: 16px;
}
.user-info-section .sign-up-input-field label,
.user-info-section .sign-up-select label {
	align-self: center;
	margin-bottom: 0;
	font-weight: 500;
	width: 9rem;
	flex-shrink: 0;
}
.user-info-section .sign-up-input-field div,
.user-info-section .sign-up-select div {
	width: 100%;
}
.user-info-section .sign-up-select button {
	height: 39px;
}
.user-info-section .sign-up-input-field input,
.user-info-section .sign-up-select button {
	font-size: 16px;
	font-weight: 300;
}
