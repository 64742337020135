.text-transform-container-portal {
	height: 130px;
	min-width: 300px;
	bottom: 0;
	left: 0;
	z-index: 100;
}
.text-transform-container {
	background-color: white;
	border-radius: 5px;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

.text-transform-text-container {
	padding: 10px 12px;
	font-size: 12px;
	font-family: var(--ara-font-family);
	color: #333333;
	line-height: 1.5;
	text-align: left;
	white-space: pre-line;
}

.text-transform-text-container > div {
	max-height: 70vh;
	overflow-y: auto;
}

.text-transform-button-container {
	position: relative;
	bottom: 0;
	left: 0;
	padding-left: 10px;
	width: 100%;
	min-height: 40px;
	border-radius: 0 0 5px 5px;
	display: flex;
	justify-content: initial;
	align-items: center;
	flex-wrap: wrap;
}

.text-transform-button-primary,
.text-transform-button-outline {
	border-radius: 5px;
	min-width: 60px;
	min-height: 25px;
	text-align: center;
	display: inline-block;
	font-size: 12px;
	font-weight: lighter;
	margin: 4px 2px;
	font-family: var(--ara-font-family);
	cursor: pointer;
	max-width: fit-content;
}

.text-transform-button-primary {
	background-color: var(--ara-blue);
	color: white;
	border: none;
}

.text-transform-button-outline {
	background-color: white;
	color: black;
	border: 1px solid #000000;
}

.text-transform-button-disabled,
.text-transform-button-outline:disabled,
.text-transform-button-primary:disabled {
	background-color: #e0e0e0;
	color: #9e9e9e;
	border: none;
	cursor: default;
}
