.clickable-dropdown .dropdown-toggle {
	padding-left: 0;
	padding-right: 0;
}

.clickable-dropdown.disabled-clickable-dropdown,
.clickable-dropdown.disabled-clickable-dropdown * {
	pointer-events: none !important;
}

.clickable-dropdown.disabled-clickable-dropdown .dropdown-toggle::after {
	display: none;
}
