.hyperlink-box {
	position: fixed;
	z-index: 20;
	background-color: white;
	border: 1px solid #807f7f;
	margin-top: 35px;
	text-align: start;
	font-size: 11px;
}

.hyperlink-box * {
	/* prevent text selection */
	user-select: none !important;
}

.hyperlink-box-container {
	display: flex;
	flex-direction: column;
	padding: 5px;
	min-width: 250px;
	width: 100%;
}

.hyperlink-box-row {
	display: flex;
	flex-direction: row;
	padding-top: 10px;
}

.hyperlink-box-link {
	display: flex;
	margin-left: 30px;
	top: 0;
	z-index: 100;
	cursor: pointer;
	font-size: 10px;
	color: var(--ara-blue);
	text-decoration: underline;
}

.hyperlink-invalid-link-box,
.hyperlink-invalid-link-box span {
	color: red !important;
	text-decoration: underline;
}

.output-text-format .hyperlink-invalid-link-box,
.hyperlink-invalid-link-box span {
	color: inherit !important;
	text-decoration: none !important;
}

.hyperlink-box-link-disabled {
	display: flex;
	margin-left: 30px;
	top: 0;
	z-index: 100;
	text-decoration: underline;
	color: #807f7f;
	cursor: not-allowed;
	font-size: 10px;
	opacity: 0.7;
}

.force-inherit-text,
.force-inherit-text span {
	color: inherit !important;
	text-decoration: inherit !important;
}
