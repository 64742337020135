.custom-dropdown-toggle {
	background-color: white;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.dropdown-toggle:hover {
	background-color: white;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.header-toggle {
	color: black;
	text-transform: capitalize;
}

.ropdown-toggle:focus {
	background-color: white;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}
