.link-button,
.link-button-active {
	font-family: var(--ara-font-family);
	width: 100px;
	height: 38px;
	right: 49.61px;
	top: calc(50% - 38px / 2 - 185.5px);

	box-shadow: 0px 0.7px 0px 1.2px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	text-decoration: none;
	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.link-button.link-button-Delete,
.link-button-active.link-button-Delete {
	margin-top: 40px;
}

.link-button.link-button-Delete {
	background-color: var(--ara-red);
	border-color: white;
	color: white;
}

.link-button-active.link-button-Delete {
	background-color: #a20000;
	border-color: white;
	color: white;
}

.link-button:hover {
	background-color: #e1e4e8;
}

.link-button-active {
	background: #6ed1e0;
	border: 1px solid #38c0d4;
}
