.RefreshButton .spinning {
	animation: spin 1s infinite linear;
}
@keyframes spin {
	from {
		transform: scale(1) rotate(0deg);
	}
	to {
		transform: scale(1) rotate(360deg);
	}
}

.RefreshButton .spinner {
	margin: auto;
}

.RefreshButton {
	width: 30px;
	height: 30px;
	border-radius: 12px;
	background-color: white;
	padding: 0;
	box-shadow: 0px 1px 0px 1px rgba(69, 69, 69, 0.4);
	position: absolute;
	top: 6px;
	right: 6px;
}
