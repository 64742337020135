@media (min-width: 920px) {
	.settings-card {
		margin: 0px auto;
		min-height: 420px;
		max-width: 840px;
	}
}
@media (max-width: 920px) {
	.settings-card {
		margin: 0px 60px;
		min-height: 420px;
	}
}

@media (max-width: 768px) {
	.settings-card {
		margin: 0px;
	}

	.settingsButtonTab {
		text-align: right;
		padding-bottom: 10px;
		border-bottom: 1px solid #b4b4b4;
	}
	.settingsButtonTab .LoaderButton {
		margin: 2px !important;
	}
}

@media (min-width: 768px) {
	.settingsButtonTab {
		text-align: right;
		width: 150px;
		padding-right: 20px;
		margin: auto;
		border-right: 1px solid #b4b4b4;
		padding-top: 20%;
		padding-bottom: 100%;
	}
}

.settings-card {
	margin-top: 20px;
	margin-bottom: 20px;
}

.settingsTab {
	text-align: center;
}

.userFolderDropdown {
	min-width: 160px;
}

.settingsButtonTab .LoaderButton {
	width: 90px;
	margin: 5px 0;
}

.settingsButtonTab .LoaderButton:active {
	background-color: var(--ara-gold);
}

.settingsForm .formField-rhs {
	vertical-align: middle;
	text-align: left;
	height: fit-content;
}

.VolumeCard {
	min-width: 140px;
	height: fit-content;
	margin-bottom: 20px;
	border: 1px solid #d9d9d9;
}

.volumeHeading {
	text-align: left;
	padding: 0 38px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.VolumeNumber {
	font-size: 30px;
	text-align: center;
}

.volumeDisplay {
	margin-bottom: 60px;
}
.sign-up-form label {
	font-size: 18px;
	margin: 20px auto;
	font-family: var(--ara-font-family);
}
.sign-up-form #defaults-tab-form {
	font-size: 16px;
	font-family: var(--ara-font-family);
}
.sign-up-form #defaults-tab-form .sign-up-input-field input {
	font-size: 16px;
	font-family: var(--ara-font-family);
	font-weight: 300;
}
.sign-up-form #defaults-tab-form label {
	font-size: 16px;
	width: 15rem;
	padding-right: 1.5rem;
	text-align: right;
	margin: 0;
	font-family: var(--ara-font-family);
	font-weight: 300;
}
.sign-up-form #defaults-tab-form .input-like-grid-with-label {
	margin-top: 9px;
}
.sign-up-form #defaults-tab-form .input-like-grid-with-label label {
	align-self: flex-start;
}

.adminTab .sign-up-form #defaults-tab-form .input-like-grid-with-label .recruiterLabel {
	margin-top: auto;
	margin-bottom: auto;
	width: 180px;
	height: 180px;
	padding: 0;
}

.adminTab .sign-up-form #defaults-tab-form .input-like-grid-with-label .recruiterPhoto {
	width: 180px;
}
