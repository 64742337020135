.h-drop-title:after {
	position: absolute;
	top: calc(50% - 3px);
	right: 2px;
	content: "";
	border-top: 3px solid black;
	border-right: 3px solid transparent;
	border-bottom: 0;
	border-left: 3px solid transparent;
}

.h-drop-title {
	position: relative;
	min-width: 30px;
	text-align: left;
}

.h-drop-popper {
	width: fit-content;
	height: fit-content;
	background-color: transparent;
}

.h-drop-item {
	display: inline-block;
	background-color: white;
	border-radius: 5px;
}

.h-drop-item > * {
	background-color: white;
}
