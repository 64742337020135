.admin-client-control-bar-title {
	font-size: 14px;
	font-weight: 500;
	color: #000000;
	margin: 0px 0px 0px 20px;
	padding: 0px;
	line-height: 40px;
	display: inline-block;
	text-transform: capitalize;
}

.admin-client-control-bar-container {
	height: 40px;
	left: 0%;
	right: 0%;
	top: 77px;
	background: #ffffff;
	box-shadow: 0px 1px 0px #d3d3d3;
}

.admin-client-control-bar-search-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
	width: 100%;
	padding: 10px 20px 0px 0px;
}

.admin-search-tooltip .tooltip-inner {
	max-width: max-content;
}
