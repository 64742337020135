.main-layout {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.layout-sidebar {
	background-color: #fff;
	border-right: 1px solid #e1e4e8;
}

.layout-content {
	background-color: #fff;
}

.layout-header {
	text-transform: capitalize;
}
