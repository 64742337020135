body {
	margin: 0;
	font-family: var(--ara-font-family);
	font-weight: 300;
	color: black;
}

h1 {
	margin: 0px auto;
	padding: 10px 0px;
	text-align: center;
	justify-content: center;
	font-size: 32px;
	font-weight: 500;
}

h2 {
	font-weight: 500;
}

h5 {
	font-weight: 500 !important;
	font-size: 13pt;
}

:root {
	--ara-blue: #2fbad1;
	--ara-dark: #1e1751;
	--ara-green: #219653;
	--ara-red: #e30000;
	--ara-gold: #daac1c;
	--ara-dark-gold: #755b0d;
	--ara-purple: #5114fe;
	--ara-pale-purple: #5114fe16;
	--ara-light-green: #b6ebd7;
	--form-border: #bbbbbb;
	--background-grey: #cccccc;
	--text-grey: #999999;
	--ara-error: #e21622;
	--ara-suceess: #017f22;

	--ara-font-family: "Roboto", "Noto Color Emoji", sans-serif;

	/* "Roboto", "Noto Color Emoji", sans-serif; */
}

.hyperLinkText {
	color: var(--ara-blue);
}
.hyperLinkText:hover {
	color: black;
}

a {
	color: black;
}
a:hover {
	color: #333333;
	text-decoration: none;
}

.clickable:hover {
	cursor: pointer;
	opacity: 0.7;
	text-decoration: none;
}

.unclickable:hover {
	cursor: not-allowed !important;
	opacity: 1 !important;
}

.standardPadding {
	padding: 6px 3px;
}
