.picker {
	position: relative;
}

.swatch-picker {
	position: relative;
}

.swatch {
	width: 22px;
	height: 22px;
	margin: 5px auto;
	border-radius: 50%;
	/* border: 1px solid #fff; */
	box-shadow:
		0 0 0 1px rgba(0, 0, 0, 0.175),
		inset 0 0 0 1px rgba(0, 0, 0, 0.225);
	cursor: pointer;
}

.swatch-picker > .swatch {
	border-radius: 3px;
}

.popover {
	text-align: center;
	position: absolute;
	top: calc(100% + 2px);
	border-radius: 9px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
	left: 0;
}

.popover.align-right {
	left: calc(50% - 200px);
}

.font-popover {
	position: absolute;
	top: calc(100% + 2px);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
	background-color: white;
	min-width: max-content;
	z-index: 30;
}

.font-popover.align-left {
	left: 0;
}

.font-popover.align-right {
	right: 0;
}

.custom-swatch-row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -5px;
}

.custom-swatch {
	width: 30px;
	height: 30px;
	margin: 5px;
	border-radius: 4px;
	position: relative;
	/* border: 1px solid #fff; */
	box-shadow:
		0 0 0 1px rgba(0, 0, 0, 0.1),
		inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.custom-swatch-button {
	font-size: 20px;
	color: gray;
	text-align: center;
}

.remove-cross {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(50%, -50%);
	height: 50%;
	border-radius: 50%;
	background-color: white;
	box-shadow: 0 0 0 0.2px rgba(256, 256, 256, 0.7);
}

.custom-swatch:hover .remove-cross {
	display: block;
}

.custom-swatch-button:hover {
	box-shadow:
		0 0 0 1px var(--ara-blue),
		inset 0 0 0 1px var(--ara-blue);
}

.font-color-picker {
	width: fit-content;
	height: fit-content;
	padding: 2px;
	padding-top: 5px;
	border-radius: 2px;
	box-shadow: 0px 0.7px 0px 1.2px rgb(0 0 0 / 25%);
	opacity: 1;
}

.color-picker-heading {
	margin-left: 6px;
	color: black !important;
	text-align: left;
	font-size: 14px;
	font-family: var(--ara-font-family);
	font-weight: 300;
}

.no-colour-label {
	margin-left: 6px;
	color: black !important;
	text-align: left;
	font-size: 13px;
	font-family: var(--ara-font-family);
	font-weight: 300;
	margin-top: 3px;
}

.color-swatches {
	display: flex;
	padding: 4px;
	flex-wrap: wrap;
	max-width: 250px;
}

.color-swatches-default {
	min-width: max-content;
}

.color-swatch {
	height: 20px;
	width: 20px;
	margin: 3px;
	border-radius: 3px;
	border: solid 1px #cccccc;
}

.color-swatch.selected-color {
	height: 24px;
	width: 24px;
	margin: 1px 1px 3px 1px;
	border-color: #aaaaaa;
}

.color-picker-text {
	margin-left: 6px;
	color: #aaaaaa !important;
	font-size: 13px;
}

.color-picker-settings-icon {
	position: absolute;
	top: 5px;
	right: 5px;
	height: 16px;
}
