.jobFormField .form-control {
	border-color: var(--form-border);
}

.jobFormField .form-control[type="file"] {
	border-color: transparent;
	border-width: 0;
}

.form-control.custom-file {
	padding: 0;
	font-weight: 400;
}

.jobFormField .form-label {
	text-align: end;
}

.jobFormField {
	margin: 10px 0;
}

.jobFormField .col-sm-2 {
	padding-left: 2px;
	padding-right: 50px;
}

.plusButton {
	background-color: white;
	border-color: white;
	border-radius: 5px;
	font-size: 35px;
	line-height: 36px;
	text-align: right;
	font-weight: 300;
	font-style: normal;
	color: #838383;
	margin: 0;
	padding: 1px 8px;
}

.logic-col {
	padding: 0 1px 4px 0;
}

.alt-col {
	padding: 0 4px 4px 0;
}

.formButton {
	background-color: white;
	line-height: 36px;
	text-align: left;
	margin: 0;
	padding: 1px 8px;
}

.plusButton:hover {
	background-color: #dddddd;
	color: #606060;
	border-color: var(--form-border);
}

.inputCol input[type="range"].range-slider.range-slider--sm::-webkit-slider-thumb {
	background: var(--ara-blue);
}

.inputCol input[type="range"].range-slider.range-slider--sm::-ms-fill-lower {
	background: var(--ara-blue);
}
.inputCol input[type="range"].range-slider.range-slider--sm::-ms-fill-upper {
	background: #b4b4b4;
}
.inputCol input[type="range"].range-slider.range-slider--sm::-moz-range-track {
	background: #b4b4b4;
}

.inputCol input[type="range"].range-slider.range-slider--sm::-moz-range-progress {
	background: var(--ara-blue);
}

.inputCol input[type="range"].range-slider.range-slider--sm::-webkit-slider-runnable-track {
	background: var(--ara-blue);
}

.inputCol input[type="range"].range-slider.range-slider--sm::-moz-range-thumb {
	background: var(--ara-blue);
}

.inputCol input[type="range"].range-slider.range-slider--sm::-ms-thumb {
	background: var(--ara-blue);
}

.custom-range {
	padding-top: 13px;
}

.inputCol .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
	background-color: transparent;
	color: #2c355a;
	opacity: 1;
	font-size: 12px;
	font-weight: 500;
	line-height: 12px;
	height: 12px;
	vertical-align: top;
}

.inputCol .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__arrow {
	background-color: transparent;
	opacity: 0;
	height: 0;
}
.inputCol input[type="range"].range-slider.range-slider--sm {
	padding-bottom: 0;
}

.userFolderDropdownToggle {
	border-color: var(--form-border);
	width: 100%;
	text-align: left;
}

.userFolderDropdownToggle#text {
	width: 90%;
}

.userFolderDropdownToggle::after {
	margin-top: 10px;
	color: var(--form-border);
	float: right;
}

.userFolderDropdown {
	border-color: var(--form-border);
	width: 100%;
}

.req-row {
	padding-bottom: 28px;
}

.inner-req-row {
	border-bottom: 1px dotted #ffffff;
}
