.toggle-switch {
	position: relative;
	width: 45px;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	text-align: center;
	margin: 0 auto;
	font-family: var(--ara-font-family);
	--toggle-background: #ddd;
	--toggle-off-background: #bbb;
	--toggle-on-background: var(--ara-green);
}
.toggle-switch-checkbox {
	display: none;
}
.toggle-switch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border: 0 solid var(--toggle-off-background);
	border-radius: 20px;
	margin: 0 0;
}
.toggle-switch-inner {
	display: block;
	width: 200%;
	margin-left: -100%;
	transition: margin 0.125s ease-in 0s;
}
.toggle-switch-inner:before,
.toggle-switch-inner:after {
	display: block;
	float: left;
	width: 50%;
	height: 22px;
	padding: 0;
	line-height: 22px;
	font-size: 10px;
	color: white;
	font-weight: 300;
	box-sizing: border-box;
}
.toggle-switch-inner:before {
	text-align: left;
	content: "On";
	padding-left: 5px;
	background-color: var(--toggle-on-background);
	color: #fff;
}
.no-inner-label-toggle-switch .toggle-switch-inner:before,
.no-inner-label-toggle-switch .toggle-switch-inner:after {
	content: "";
	/* display: none; */
}

.dual-toggle-switch {
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: space-between;
}

.dual-toggle-switch > * {
	height: 22px;
	font-size: 14px;
	font-weight: 400;
}

.dual-toggle-switch .toggle-switch {
	--toggle-off-background: #d8d8d8;
}

.toggle-inner-disabled {
	background-color: var(--toggle-background) !important;
	cursor: not-allowed;
}
.toggle-inner-disabled:before {
	background-color: var(--toggle-background) !important;
	cursor: not-allowed;
}
.toggle-inner-disabled:after {
	background-color: var(--toggle-background) !important;
	cursor: not-allowed;
}
.toggle-switch-disabled {
	cursor: not-allowed;
}
.toggle-switch-disabled:before {
	cursor: not-allowed;
}
.toggle-switch-inner:after {
	content: "Off";
	padding-right: 6px;
	background-color: var(--toggle-off-background);
	color: #fff;
	text-align: right;
}
.toggle-switch-switch {
	display: block;
	width: 18px;
	height: 18px;
	margin: 2px;
	background: #fff;
	position: absolute;
	/* top: 0;
	bottom: 0; */
	right: 23px;
	border: 0 solid var(--toggle-off-background);
	border-radius: 20px;
	transition: all 0.125s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
	margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
	right: 0px;
}
.live-draft-toggle {
	width: 100%;
	height: 120%;
	pointer-events: none;
	display: flex;
	gap: 5px;
	margin-right: 5px;
	justify-content: center;

	align-items: center;
}
.live-draft-toggle .toggle-switch {
	pointer-events: none;
}
.live-draft-toggle .toggle-switch .toggle-switch-label,
.live-draft-toggle .toggle-switch .toggle-switch-checkbox {
	pointer-events: all;
}

.live-draft-toggle .toggle-switch .toggle-switch-inner:before {
	content: "Live";
	padding-left: 5px;
}

.live-draft-toggle .toggle-switch .toggle-switch-inner:after {
	content: "Draft";
	padding-right: 4px;
}
.live-draft-toggle .toggle-switch .toggle-switch-inner:before,
.live-draft-toggle .toggle-switch .toggle-switch-inner:after {
	font-size: 9px;
}
