.copied-flash-showing {
	margin: 5px;
	font-size: 10px;
	color: #888888;
	visibility: visible;
	opacity: 0;
	animation: flash-copied 0.8s ease-in-out;
}

.copied-flash-hidden {
	opacity: 0;
	visibility: hidden;
}

@keyframes flash-copied {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	5% {
		opacity: 1;
		visibility: visible;
	}
	50% {
		opacity: 1;
		visibility: visible;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}
