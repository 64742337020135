.text-format .table-cell-content {
	padding: 0px 2px 1px;
	width: 100%;
	margin: 0.5px;
}

.editing-text-format .table-cell-content {
	padding: 1px 2px 2px;
}

.table-container {
	margin-left: var(--minus-format-area-padding);
	padding-left: var(--format-area-padding);
	margin-right: var(--minus-format-area-padding);
	padding-right: var(--format-area-padding);
	position: relative;
	padding-bottom: 10px;
	width: calc(100% + 2 * var(--format-area-padding));
}

.editing-text-format .table-container {
	cursor: default;
	/* overflow: auto visible; */
}

.narrow-text .editing-text-format .table-container {
	overflow-x: auto;
}

.narrow-text .editing-text-format .table-container {
	padding-top: 16px;
}

.table-width-arrow > img {
	margin: auto;
}

.table-container:not(.table-container-active) .table-width-arrow {
	display: none;
}

.table-container-active,
.table-container-active .table-width-arrow {
	background-color: #fafafa;
	opacity: 0.9;
}

.table-container-active .table-width-arrow {
	width: 100%;
	padding-left: var(--format-area-padding);
	padding-bottom: 5px;
	position: absolute;
	left: 0;
	top: -20px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.table-width-arrow::after {
	position: absolute;
	top: 0px;
	left: 50%;
	content: "Full Width";
	font-size: 8px;
	color: grey;
	transform: translateX(-50%);
}

.editing-text-format .slate-table {
	cursor: text;
}

/* .text-format .slate-table {
    border-left: 1px solid black;
    border-top: 1px solid black;
    width: 480px;
    min-width: fit-content;
    min-width: 80%;
} */

.text-format .slate-table {
	position: relative;
}

.editing-text-format .slate-table {
	margin-bottom: 20px;
}

/* .editing-text-format .table-container-active .slate-table {
    margin: 15px 30px 20px 3px;
} */

.text-format .tbody {
	/* display: grid; */
	/* border-right: 1px solid black;
    border-bottom: 1px solid black; */
	margin-top: 1px;
	border-color: var(--box-shadow-color);
}

.text-format .vertical-add-highlight::after {
	background-color: var(--ara-blue);
	height: calc(100% + 1px);
	width: 2px;
	position: absolute;
	content: "";
	top: -0.5px;
	right: -2.5px;
	z-index: 8;
}

.text-format .horizontal-add-highlight::after {
	background-color: var(--ara-blue);
	width: calc(100% + 1px);
	height: 2px;
	position: absolute;
	content: "";
	bottom: -2.5px;
	left: -0.5px;
	z-index: 8;
}

.td {
	position: relative;
	--box-shadow-color: inherit;
	/* border-left: 1px solid black;
    border-top: 1px solid black; */
	box-shadow:
		0 0 0 0.5px var(--box-shadow-color) inset,
		0 0 0 0.5px var(--box-shadow-color);
	/* use half inset half not to avoid having to reposition  so that cell width is correct*/
}

.td:has(.hyperlink-box) {
	z-index: 2 !important;
	/* this ensure the stacking context of the hyperlink box is higher than the table elements. 
    Not currently supported in FF but rare that the problem happens anyway */
}

.editing-text-format .add-table-circle {
	opacity: 0;
	height: 15px;
	width: 15px;
	z-index: 20;
	position: absolute;
	top: 50%;
	left: 50%;
	border-radius: 50%;
	transform: translate(-50%, -50%);
}

.editing-text-format .add-table-circle-container {
	height: 15px;
	width: 15px;
	border-radius: 50%;
	border: white solid 4.5px;
	background-color: #cccccc;
}

.editing-text-format .add-table-circle:hover {
	opacity: 1;
	background-color: white;
	cursor: pointer;
}

.editing-text-format .vertical-delete-highlight,
.editing-text-format .horizontal-delete-highlight {
	/* deletion highlighting */
	background-color: rgb(0 0 0 / 10%);
}

.text-format .tr {
	display: contents;
}

.text-format .table-cell-border {
	touch-action: none;
	position: absolute;
	right: -4px;
	top: 0;
	width: 10px;
	height: 100%;
	z-index: 1;
}

.editing-text-format .table-cell-border {
	cursor: col-resize;
}

.table-spacer {
	height: 0;
	width: 0;
}

.table-spacer p {
	display: block;
	position: relative;
	width: 10px;
	color: transparent;
	page-break-inside: avoid;
	user-select: none;
}

.spacing-compact .text-format .table-container {
	padding-bottom: 5px;
}

.spacing-spaced .text-format .table-container {
	padding-bottom: 15px;
}

.editing-text-format .table-options-button {
	display: block;
}

.table-options-button {
	position: relative;
	width: max-content;
	padding: 2px 5px;
	background-color: white;
	margin: 5px auto 0;
	border-radius: 2px;
	box-shadow:
		0 0 0 0.5px black,
		0 2px 3px 0 rgb(0 0 0 / 25%);
	border: none;
	color: black;
}

.text-format:not(.editing-text-format) .table-options-button,
.text-format:not(.editing-text-format) .table-options-button * {
	display: none;
}

.table-container .h-drop-title::after {
	display: none;
}

.editing-text-format .table-options-button::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.table-options-dropdown {
	box-shadow: 0px 0.7px 0px 0.8px rgb(0 0 0 / 25%);
	border-radius: 3px;
	padding: 2px 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
}

.table-dropdown-option {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 30px;
}

.table-option-label {
	font-family: var(--ara-font-family);
	font-size: 14px;
	padding: 0 10px 0 3px;
}

.table-dropdown-heading {
	font-size: 12px;
	color: #aaaaaa;
	font-weight: 500;
}

.merge-arrow {
	width: 22px;
	height: 22px;
	margin: 5px 0px 5px auto;
}

.merge-arrow-left {
	transform: rotate(180deg);
}

.merge-arrow-up {
	transform: rotate(-90deg);
}

.merge-arrow-down {
	transform: rotate(90deg);
}

.table-options-row {
	width: calc(100% + 18px);
}

.table-container:not(.table-container-selected) .table-options-row {
	display: none;
}

/* .text-format .table-p {
    margin-bottom: -0.4rem;
} */

.table-p p:last-child,
.table-p ul:last-child,
.table-p ol:last-child {
	margin-bottom: 0 !important;
}
